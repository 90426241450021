.App {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}

header {
  position: sticky !important;
}

header a {
  cursor: pointer;
}

footer .footer-innovation-links a {
  order: 2;
}

footer .footer-innovation-links nav {
  order: 1;
}

.profile-photo {
  height: 180px;
  width: auto;
}

.page-title {
  font-size: 2.5rem;
  letter-spacing: -0.0875rem;
  line-height: 2.75rem;
}

.member-name {
  letter-spacing: -0.0525rem;
}

.member-title {
  font-size: 1rem;
  letter-spacing: -0.01875rem;
  line-height: 1.625rem;
  margin: 0;
}

div.core-chapter-select-group input {
  padding: 0 !important;
}

div.core-chapter-select-group input:focus {
  border: none !important;
}

.table-error-pad {
  display: inline-block;
}

.grip {
  cursor: grab;
  float: right;
}

.card-header-text {
  display: inline;
}